import { React, useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logo from "./logo.svg";
import "./App.css";
import { Button, Card } from "reactstrap";
import Accord from "./Accordian";
import Webcard from "./Card";
import Headercard from "./HeaderCard";
import Content from "./Content";
import Footer from "./Footer";
import SendMessages from "./SendMessage";
import axios from "axios";
import Services from "./pages/services";
import Contact from "./pages/contact";
import Home from "./pages/home";
import About from "./pages/about";
import Blog from "./pages/blog";
import Videos from "./pages/videos";
import NavBar from "./navbar";
import BlogTemplate from "./BlogTemplate";
import PromptTemplate from "./PromptTemplate";
import Prompts from "./pages/prompts";
import Register from "./pages/register";
import CommentSection from "./pages/comments";
import ServicesTemplate from "./ServicesTemplate";

const App = () => {
	const [blog_data, setblogData] = useState([]);
	const [prompt_data, setpromptData] = useState([]);

	const [video_data, setvideoData] = useState([]);
	const [videoUrl, setVideoUrl] = useState("");
	const [commentsData, setCommentsData] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	// Define your service data (replace with your actual data)
	const servicesData = [
		{
			id: 1,
			title: "Human-Centered Services",
			image: "/s1.png",
			description:
				"Unlocking human potential through digital skills and wellbeing.",

			comp1: "Digital Literacy Training: Basic digital skills training.",
			comp2: "AI Literacy Programs: AI understanding and application training.",
			comp3:
				"Human-Centered Skill Development: Communication, collaboration and problem-solving skills.",
			comp4:
				"Digital Wellbeing & Professional Development Coaching: Healthy digital habits guidance. Career growth and digital proficiency.",
		},
		{
			id: 2,
			title: "Technology-Centered Services",
			image: "/s2.png",
			description:
				" Empowering businesses through cutting-edge technology solutions.",
			comp1:
				"Software & Web Application Development: Web and mobile applications. Custom software solutions.",
			comp2: "Digital Infrastructure: Hosting, maintenance and security.",
			comp3: "AI Solutions: AI-powered tools and integration.",
			comp4:
				"Digital Transformation: Comprehensive tech solutions for business growth and value addition.",
		},
		{
			id: 3,
			title: "Strategic Consulting Services",
			image: "/s3.png",
			description: "Informing business strategy with expert digital guidance.",
			comp1:
				"Digital Strategy: Aligning business goals with digital solutions.",
			comp2:
				"Innovation Consulting: Leveraging AI, blockchain and emerging technologies.",
			comp3: "Technology Roadmapping: Custom plans for business growth.",
			comp4: "IT Advisory: Expert guidance for informed tech decisions.",
		},
	];

	const getVideoData = async () => {
		try {
			const response = await axios.get("data/videos.json");
			const mydata = response.data;

			setvideoData(mydata);
		} catch (error) {
			console.error(error);
		}
	};

	const getBlogData = async () => {
		try {
			const response = await axios.get("data/posts.json");
			const mydata = response.data;

			setblogData(mydata);
		} catch (error) {
			console.error(error);
		}
	};

	const getPromptData = async () => {
		try {
			const response = await axios.get("data/prompts.json");
			const mydata = response.data;

			setpromptData(mydata);
		} catch (error) {
			console.error(error);
		}
	};

	// Fetch comments using Axios (similar to getVideoData)
	const fetchComments = async () => {
		setIsLoading(true);
		setError(null);

		try {
			const response = await axios.post(
				"https://youtubecomments.vercel.app/get_comments",
				{
					video_url: videoUrl,
				},
			);

			if (response.status !== 200) {
				throw new Error("Failed to fetch comments");
			}

			const comments = response.data;

			setCommentsData({ comments });
			console.log(commentsData);
		} catch (error) {
			console.error("Error fetching comments:", error);
			setError("An error occurred while fetching comments.");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getVideoData();
		getBlogData();
		getPromptData();
	}, []);
	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					minHeight: "100%",
				}}>
				<div style={{ flex: "1" }}>
					<NavBar />

					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route
							path="/services"
							element={<Services servicesData={servicesData} />}
						/>
						<Route path="/contact" element={<Contact />} />
						<Route path="/register" element={<Register />} />
						<Route path="/blog" element={<Blog data={blog_data} />} />
						<Route
							path="/blog/:postId"
							element={<BlogTemplate data={blog_data} />}
						/>
						{/*A nested route!*/}
						<Route path="/videos" element={<Videos videodata={video_data} />} />
						<Route path="/prompts" element={<Prompts data={prompt_data} />} />
						<Route
							path="/prompts/:postId"
							element={<PromptTemplate data={prompt_data} />}
						/>
						<Route
							path="/comments"
							element={
								<CommentSection
									data={commentsData}
									fetchComments={fetchComments}
									videoUrl={videoUrl}
									setVideoUrl={setVideoUrl}
									isLoading={isLoading}
									error={error}
								/>
							}
						/>
						<Route
							path="/services/:serviceId"
							element={<ServicesTemplate servicesData={servicesData} />}
						/>
					</Routes>
				</div>
				<Footer />
			</div>
		</>
	);
};

export default App;
