import { React, useState, useEffect, useContext, props } from "react";
import "../App.css";
import {
	Card,
	Row,
	Col,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";
import Accord from "../Accordian";

const About = () => {
	return (
		<>
			<div
				className="Card"
				style={{
					backgroundColor: "transparent",
					zIndex: 1,
				}}>
				<div className="m-3">
					<Card className="card animate__animated animate__fadeInUp">
						<CardBody className="p-1">
							<CardTitle tag="h2">About</CardTitle>
							<Row className="d-flex flex-row justify-content-center align-items-center">
								<Col sm="12">
									<div>
										<CardText tag="h5">
											Bitnnovative empowers individuals and organizations to
											thrive in the digital age. We foster essential
											human-centered skills, cultivate digital literacy, and
											promote AI literacy to enable informed and responsible
											engagement with technology. Through training, coaching,
											and consulting, we bridge the gap between human
											capabilities and AI-driven innovation. Our comprehensive
											approach helps individuals and businesses navigate the
											evolving digital landscape, unlock the full potential of
											AI, and achieve digital well-being. With Bitnnovative, you
											can confidently embrace technology, drive growth, and
											create a future where humans and AI collaborate
											seamlessly.
										</CardText>
									</div>
								</Col>

								<Row className="d-flex flex-row justify-content-center align-items-center pt-3">
									<Col sm="3">
										<div className="bar1">Elevate</div>
									</Col>
									<Col sm="3">
										<div className="bar2">Educate</div>
									</Col>
									<Col sm="3">
										<div className="bar3">Innovate</div>
									</Col>
								</Row>
							</Row>
							<Row className="d-flex flex-row justify-content-center align-items-center pt-3">
								<Accord />
							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
};

export default About;
