import { React, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../App.css";
import {
	Card,
	Row,
	Col,
	CardBody,
	CardTitle,
	CardText,
	CardImg,
} from "reactstrap";
import Accord from "../Accordian";

const Services = ({ servicesData }) => {
	const navigate = useNavigate(); // Get the navigate function

	const handleChange = (serviceId) => {
		navigate(`/services/${serviceId}`);
	};

	return (
		<>
			<div className="Card" style={{ backgroundColor: "transparent" }}>
				<div className="m-5">
					<Card className="card animate__animated animate__fadeInUp">
						<CardBody>
							<CardTitle tag="h2">Services</CardTitle>
							<Row className="d-flex flex-row justify-content-center align-items-center">
								{servicesData.map((service) => (
									<Col
										sm="3"
										className="mb-3"
										key={service.id}
										onClick={() => handleChange(service.id)}>
										<div>
											<CardImg
												alt="Card image cap"
												bottom
												src={service.image}
												style={{ height: 200, width: 200 }}
												width="100%"
											/>
											<CardText tag="h4">{service.title}</CardText>
										</div>
									</Col>
								))}
							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
};

export default Services;
