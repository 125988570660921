import { useParams } from "react-router-dom";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { FiHeart, FiBookOpen } from "react-icons/fi";

import blog_img from "./images/blog_img.png";
import { useMediaQuery } from "react-responsive";

const ServicesTemplate = ({ servicesData }) => {
	const { serviceId } = useParams(); // Get the serviceId from the URL params

	// Find the service data based on the ID
	const service = servicesData.find((s) => s.id === parseInt(serviceId));
	const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

	return isMobile ? (
		<Card className="card animate__animated animate__fadeInUp m-3">
			<CardBody>
				<CardTitle tag="h2">{service.title}</CardTitle>

				<Row className="d-flex flex-row justify-content-center align-items-center py-5">
					<Row sm="12">
						<div style={{ textAlign: "justify" }}>
							<CardText tag="h5">{service.description}</CardText>
							<CardText tag="h5">{service.comp1}</CardText>
							<CardText tag="h5">{service.comp2}</CardText>
							<CardText tag="h5">{service.comp3}</CardText>
							<CardText tag="h5">{service.comp4}</CardText>
						</div>
					</Row>
				</Row>
			</CardBody>
		</Card>
	) : (
		// <a href={props.video.url}>
		// 	<h3>{props.video.title}</h3>
		// 	<p>{props.video.description}</p>
		// </a>
		<Card className="card animate__animated animate__fadeInUp m-3">
			<CardBody>
				<CardTitle tag="h2">{service.title}</CardTitle>

				<Row className="d-flex flex-row justify-content-center align-items-center p-5">
					<Row sm="12">
						<div style={{ textAlign: "justify" }}>
							<CardText tag="h5">{service.description}</CardText>
							<CardText tag="h5">{service.comp1}</CardText>
							<CardText tag="h5">{service.comp2}</CardText>
							<CardText tag="h5">{service.comp3}</CardText>
							<CardText tag="h5">{service.comp4}</CardText>
						</div>
					</Row>
				</Row>
			</CardBody>
		</Card>
	);
};

export default ServicesTemplate;
