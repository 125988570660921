import { React, useState, useEffect, useContext, props } from "react";
import "../App.css";
import contactus from "../contactus.png";
import ContactForm from "../contactform";
import Iframe from "react-iframe";
import { FiEye } from "react-icons/fi";
import {
	Card,
	Row,
	Col,
	CardBody,
	CardDeck,
	CardTitle,
	CardText,
	CardImg,
	CardImgOverlay,
} from "reactstrap";
import Accord from "../Accordian";

const Register = () => {
	return (
		<>
			<div
				className="Card"
				style={{
					backgroundColor: "transparent",
					marginTop: "20px",
				}}>
				<Col
					md={{
						offset: 3,
						size: 6,
					}}
					sm="12">
					<div className="mx-5">
						<Card className="contact_card animate__animated animate__fadeInUp">
							<CardBody>
								<CardTitle tag="h2">
									Introduction to Image Generation with Meta AI
								</CardTitle>

								<Row className="d-flex flex-row justify-content-center align-items-center">
									<Iframe
										width="640px"
										height="480px"
										src="https://forms.office.com/r/880r5fd9Ct?embed=true"
										frameborder="0"
										marginwidth="0"
										marginheight="0"
										style="border: none; max-width:100%; max-height:100vh"
										allowfullscreen
										webkitallowfullscreen
										mozallowfullscreen
										msallowfullscreen>
										{" "}
									</Iframe>
									<Col sm="12">
										<div className="my-5">
											<FiEye color="#ff9900" size={72} />

											<p>Follow the Bitnnovative channel on WhatsApp</p>
											<a
												href="https://whatsapp.com/channel/0029VaEDbhA5PO14cKF9pc3x"
												target="_blank">
												https://whatsapp.com/channel/0029VaEDbhA5PO14cKF9pc3x
											</a>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</div>
				</Col>
			</div>
		</>
	);
};

export default Register;
