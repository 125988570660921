import { React, useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";

import PromptItem from "../PromptItem";
import { useNavigate } from "react-router-dom";

const Prompts = (props, emptyHeading) => {
	const navigate = useNavigate();

	const count = 0;
	let heading = emptyHeading;
	if (count > 0) {
		const noun = count > 1 ? "Posts" : "Post";
		heading = count + " " + noun;
	}

	return (
		<section>
			{/* <h2>{heading}</h2> */}

			{props.data &&
				props.data.prompts.map((item, index) => (
					<PromptItem key={index} post={item} navigate={navigate} />
				))}
		</section>
	);
};

export default Prompts;
